import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeLandingPageSkeleton } from './TypeLandingPage';
import type { TypePageSectionSkeleton } from './TypePageSection';

export interface TypeLinkFields {
  identifier: EntryFieldTypes.Symbol;
  relativeUrl?: EntryFieldTypes.Symbol;
  externalUrl?: EntryFieldTypes.Text;
  internalRef?: EntryFieldTypes.EntryLink<TypeLandingPageSkeleton | TypePageSectionSkeleton>;
}

export type TypeLinkSkeleton = EntrySkeletonType<TypeLinkFields, 'link'>;
export type TypeLink<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeLinkSkeleton, Modifiers, Locales>;

export function isTypeLink<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeLink<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'link';
}
