import { Entry } from 'contentful';
import { isTypeButton, TypeButtonSkeleton } from '../../../../@types/generated/TypeButton';
import {
  isTypeContextualButton,
  TypeContextualButtonSkeleton,
} from '../../../../@types/generated/TypeContextualButton';
import { getLinkHref } from './linkHref';

const buttonToCompatibleProps = ({
  button,
}: {
  button?: Entry<
    TypeButtonSkeleton | TypeContextualButtonSkeleton,
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
  >;
}) => {
  if (button && isTypeButton(button)) {
    return {
      label: button.fields.label,
      prompt: button.fields.prompt,
      href: getLinkHref(button.fields.link) || button.fields.href,
      colorContext: button.fields.colorContext,
      size: button.fields.size,
      variant: button.fields.variant,
      trailingIconName: button.fields.trailingIconName,
      leadingIconName: button.fields?.leadingIconName,
    };
  } else if (button && isTypeContextualButton(button)) {
    return {
      label: button.fields.label,
      prompt: button.fields.prompt,
      href: getLinkHref(button.fields.link),
    };
  }
};

export default buttonToCompatibleProps;
