import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeLandingPageSkeleton } from './TypeLandingPage';

export interface TypeImageLinkFields {
  title?: EntryFieldTypes.Symbol;
  image?: EntryFieldTypes.AssetLink;
  relativeUrl?: EntryFieldTypes.Symbol;
  link?: EntryFieldTypes.Symbol;
  richText?: EntryFieldTypes.RichText;
  description?: EntryFieldTypes.RichText;
  pageLink?: EntryFieldTypes.EntryLink<TypeLandingPageSkeleton>;
}

export type TypeImageLinkSkeleton = EntrySkeletonType<TypeImageLinkFields, 'imageLink'>;
export type TypeImageLink<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeImageLinkSkeleton, Modifiers, Locales>;

export function isTypeImageLink<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeImageLink<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'imageLink';
}
