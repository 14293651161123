import { isTypeImageLink, TypeImageLink, TypeLink } from '../../../../@types/generated';
import { isTypeLandingPage } from '../../../../@types/generated/TypeLandingPage';
import { isTypePageSection } from '../../../../@types/generated/TypePageSection';
import { getLandingPageUrl } from './pageUrl';
import { getSectionId } from './sectionToCompatibleProps';

const getHrefFromContentfulReference = ({
  reference,
}: {
  reference: TypeLink['fields']['internalRef'] | TypeImageLink['fields']['pageLink'];
}) => {
  if (!reference) {
    return;
  }
  if (isTypeLandingPage(reference)) {
    return getLandingPageUrl({
      slug: reference.fields.slug,
      pageTags: reference.fields.tags,
    });
  } else if (isTypePageSection(reference)) {
    const id = getSectionId({ section: reference });
    return `#${id}`;
  }
};

export const getLinkHref = (link?: TypeLink | TypeImageLink) => {
  if (!link) return undefined;

  if (isTypeImageLink(link)) {
    return (
      link.fields?.relativeUrl ||
      link.fields?.link ||
      getHrefFromContentfulReference({ reference: link.fields?.pageLink })
    );
  }

  return (
    link.fields?.relativeUrl ||
    link.fields?.externalUrl ||
    getHrefFromContentfulReference({ reference: link.fields?.internalRef })
  );
};
